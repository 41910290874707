@mixin fontdef($FontName: 'uni-neue', $FontType: 'regular') {
  font-family: '#{$FontName}';
  src: local('#{$FontName}'),
    url('./fonts/#{$FontName}/#{$FontType}/#{$FontName}-#{$FontType}.woff2')
      format('woff2'),
    local('#{$FontName}'),
    url('./fonts/#{$FontName}/#{$FontType}/#{$FontName}-#{$FontType}.woff')
      format('woff');
}

// -----------------------------------------------------------------------------
$FontName: 'uni-neue';

/* Light */
@font-face {
  @include fontdef(#{$FontName}, 'light');
  font-weight: 300;
  font-style: normal;
}

/* Regular */
@font-face {
  @include fontdef(#{$FontName}, 'regular');
  font-weight: normal;
  font-style: normal;
}

/* Bold */
@font-face {
  @include fontdef(#{$FontName}, 'bold');
  font-weight: bold;
  font-style: normal;
}
