.dropdown-menu--container {
  @apply relative;
}

.dropdown-menu--button {
  @apply text-gray
          flex
          flex-row
          relative
          items-center;

  span {
    @apply ml-xs;
  }
}

.dropdown-menu--options {
  @apply absolute
          mt-xs
          p-sm
          bg-white
          rounded
          z-front
          shadow-md
          right-0
          min-w-max
          space-y-md;

  div {
    @apply block;
  }
}
