:root {
  // Generic gray-ish theme
  --primary: #004b8d;
  --primaryInteraction: #00437e;
  --secondary: #e5f1fb;
  --disabled: rgba(229, 241, 251, 0.5); // 50% --secondary
  --componentBg: white;
  --bodyBg: #f5f5f5;
  --appFontFamily: 'uni-neue', sans-serif;
  --appHeadingFontFamily: 'uni-neue', sans-serif;
  --borderButton: 5rem;
  --borderField: 0px;
  --borderFieldColor: #121b22;
  --borderFieldFocusColor: var(--primary);
  --borderPanel: 0;
  --orderText: white;
  --gray: #061423;
  --lightGray: #eeeeef;

  // Font-weight
  --lightFont: 300;
  --normalFont: 400;
  --boldFont: 600;
  --headingFontWeight: var(--normalFont);

  // Colors
  --success: #0f9a65;
  --info: #1d4582;
  --warn: #bf6203;
  --error: #cd0039;
  --gray: #090909;
  --silver: #a7a9ac;
  --activeColor: #0a84ff;
  --activeDisableColor: #71bdff;
  --lightBlue: #0066c0;
  --disableColor: #c8c8c8;
}
